import Case from 'case';
import { find } from 'ramda';
import airports from './airports.json';

export const getAirport = (code) => {
  const airport = find(({ airportCode }) => airportCode === code)(airports);

  if (!airport) {
    return {
      code,
      name: 'MISSING FROM DB',
    };
  }

  const { airportCode, airportName } = airport;

  return {
    code: airportCode,
    name: airportName,
  };
};

export const parseSegment = (segment) => ({
  origin: getAirport(segment.origin_code),
  destination: getAirport(segment.destination_code),
  flightNumber: `${segment.carrier_code}${Number(segment.flight_number)}`,
  flightClass: Case.title(segment.travel_class),
  fareClass: segment.fare_class,
  fareBasis: segment.fare_basis,
  carrierDescription: segment.carrier_description,
  duration: segment.duration,
  departure: {
    date: segment.departure_date,
    time: segment.departure_time,
  },
  arrival: {
    date: segment.arrival_date,
    time: segment.arrival_time,
  },
});

const filterByDirection = (direction) => (segment) =>
  segment.direction === direction;

const parseFlightSegments = (segments) => ({
  outbound: segments.filter(filterByDirection('outbound')).map(parseSegment),
  inbound: segments.filter(filterByDirection('inbound')).map(parseSegment),
});

export default parseFlightSegments;
