import { getAirport } from '@/lib/parser/parseFlightSegments';
import { EcommerceDetailedItem, PointsValue } from '@/libs/v2/utils/dataLayer';
import getEcommerceItem, {
  EcommerceItemProps,
} from './getEcommerceItemEventData';
import { Destination } from '@/v2/types/ui';

import { differenceInDays, startOfDay } from 'date-fns';

export const getNightsStay = (
  departureDate?: Date | string | undefined,
  returnDate?: Date | string | undefined,
): number | undefined => {
  if (departureDate && returnDate) {
    return differenceInDays(
      startOfDay(
        returnDate instanceof Date ? returnDate : new Date(returnDate),
      ),
      startOfDay(
        departureDate instanceof Date ? departureDate : new Date(departureDate),
      ),
    );
  }

  return undefined;
};

type EcommerceDetailedItemProps = Omit<
  EcommerceItemProps,
  'index' | 'forceOverridePrice'
> & {
  paymentType: PointsValue;
  availableOfferCount: number;
  availableRoomCount: number;
  travellers: {
    adults: number;
    children: number;
    infants: number;
  };
  travelDateRange: {
    start?: string;
    end?: string;
  };
  leadInRoom: any | undefined;
  isPreSearch: boolean;
};
const getPropertyLocation = (destination: Destination): string => {
  return [destination.title, destination.countryName]
    .filter((value) => !!value)
    .join(', ');
};

export const getEcommerceDetailedItem = ({
  travelDateRange,
  leadInRoom,
  ...ecommerceItemProps
}: EcommerceDetailedItemProps): EcommerceDetailedItem => {
  let destination = ecommerceItemProps.property.propertyContent.destination;
  let route = `${ecommerceItemProps.originCode}${destination.searchConfig?.destinationCode}`;

  const matchingLeadInOffer = leadInRoom?.matchingLeadInOffer;

  let postSearchPrice = leadInRoom?.matchingOffer?.charges?.total?.amount;
  const extraDataPoints = ecommerceItemProps.isPreSearch
    ? {
        price: matchingLeadInOffer?.packageOffers?.[route]?.total?.amount,
        original_price:
          matchingLeadInOffer?.packageOffers?.[route]?.valuedAtTotal?.amount,
        number_of_nights: matchingLeadInOffer?.minNumberOfNights,
        travellers_adult: matchingLeadInOffer?.travellers.adults,
        travellers_children: matchingLeadInOffer?.travellers.children,
        travellers_infant: matchingLeadInOffer?.travellers.infants,
      }
    : {
        price:
          typeof postSearchPrice === 'string'
            ? Number(postSearchPrice)
            : postSearchPrice,
        original_price: undefined,
        number_of_nights: getNightsStay(
          travelDateRange?.start,
          travelDateRange?.end,
        ),
        travellers_adult: ecommerceItemProps.travellers?.adults,
        travellers_children: ecommerceItemProps.travellers?.children,
        travellers_infant: ecommerceItemProps.travellers?.infants,
      };

  const item = getEcommerceItem({
    ...ecommerceItemProps,
    index: 1,
    price: extraDataPoints.price,
    forceOverridePrice: true,
  });

  const numberOfPassengers =
    (extraDataPoints.travellers_adult ?? 0) +
    (extraDataPoints.travellers_children ?? 0) +
    (extraDataPoints.travellers_infant ?? 0);

  return {
    ...item,
    ...extraDataPoints,
    item_variant: leadInRoom?.roomType.name,
    recommend: undefined, // Not currently a Holidays feature
    rating: ecommerceItemProps.property.propertyContent.rating,
    image_count:
      ecommerceItemProps.property.propertyContent.images?.length ?? 0,
    start_date: travelDateRange?.start ?? undefined,
    end_date: travelDateRange?.end ?? undefined,
    origin:
      ecommerceItemProps.originCode &&
      getAirport(ecommerceItemProps.originCode).name,
    destination: destination.title,
    number_of_rooms: 1, // Hardcoded due to no multi room support
    number_of_passengers: numberOfPassengers || undefined,
    available_offer_count: ecommerceItemProps.availableOfferCount,
    available_room_count: ecommerceItemProps.availableRoomCount,
    exclusive_offer:
      !!ecommerceItemProps.property.procuredOffer?.isLuxuryHolidayOffer,
    offer_type: !!ecommerceItemProps.property.procuredOffer
      ? 'Procured Offer'
      : 'Standard',
    rebook: undefined,
    payment_type: ecommerceItemProps.paymentType,
    location: getPropertyLocation(destination),
    international_or_domestic:
      destination.country !== 'AU' ? 'International' : 'Domestic',
    domestic_type: undefined,
  };
};

export default getEcommerceDetailedItem;
